import { debounce } from 'throttle-debounce'

export default (videos = []) => ({
    videos: [],
    src: null,
    type: null,
    canAutoPlay: true,
    isMuted: true,
    isPlaying: false,
    isEnded: false,

    calculateVideoSource() {
        const isHorizontal = window.innerWidth > window.innerHeight
        const video = this.videos.at(isHorizontal ? 1 : 0)
        const currentTime = this.$refs.video.currentTime || 0.01

        this.src = `${video.url}#t=${currentTime}`
        this.type = video.mime_type
    },

    onResize() {
        this.calculateVideoSource()

        setTimeout(() => {
            if (this.isPlaying) {
                this.play()
            }
        })
    },

    toggleMute() {
        this.$refs.video.muted = !this.$refs.video.muted
    },

    play() {
        return this.$refs.video.play().then(() => (this.canAutoPlay = true))
    },

    replay() {
        this.$refs.video.currentTime = 0
        this.play()
    },

    init() {
        const boundOnResize = this.onResize.bind(this)
        const video = this.$refs.video

        this.videos = videos
        video.addEventListener('play', () => (this.isPlaying = true))
        video.addEventListener('pause', () => (this.isPlaying = false))
        video.addEventListener(
            'volumechange',
            () => (this.isMuted = video.muted)
        )
        this.$watch('isPlaying', () => (this.isEnded = video.ended))
        window.addEventListener('resize', debounce(300, boundOnResize))
        this.calculateVideoSource()
        setTimeout(() =>
            this.play()
                .then()
                .catch(() => (this.canAutoPlay = false))
        )
    },
})
