import Alpine from 'alpinejs'
import takeover from '@/components/takeover.js'

//
// Styles
//
import '../css/style.css'

//
// Setup Alpine
//
window.Alpine = Alpine

//
// Setup Alpine components
//
Alpine.data('takeover', takeover)

//
// Initialize Alpine
//
Alpine.start()
